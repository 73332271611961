import { FC, MouseEvent } from 'react';

import { Controller, useFormContext, useWatch } from 'react-hook-form';
import {
  Box,
  FormGroup,
  FormLabel,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import PaymentFormDeliveryInputs from './PaymentFormDeliveryInputs';

import { orderMethodMapping, paymentMethodMapping } from '@/constants/paymnet';
import { OrderMethodsEnum } from '@/store/slices/order/types';
import { PaymentMethodsEnum } from '@/types';

const PaymentFormFields: FC = () => {
  const theme = useTheme();
  const isMobileBreakpoint = useMediaQuery(theme.breakpoints.down('md'));
  const { control, register, setValue } = useFormContext();

  const orderMethod = useWatch({
    control,
    name: 'orderMethod',
  });

  const handleChangeOrderMethod = (event: MouseEvent<HTMLElement>, method: OrderMethodsEnum | null) => {
    if (!method) return;
    setValue('orderMethod', method);

    if (method === OrderMethodsEnum.Glovo) {
      setValue('paymentMethod', PaymentMethodsEnum.Glovo);
    }
  };

  const handleChangePaymentMethod = (event: MouseEvent<HTMLElement>, method: PaymentMethodsEnum | null) => {
    if (!method) return;
    setValue('paymentMethod', method);

    if (method === PaymentMethodsEnum.Glovo) {
      setValue('orderMethod', OrderMethodsEnum.Glovo);
    }
  };

  return (
    <>
      <FormGroup sx={{ mb: 2 }}>
        <FormLabel>Amount</FormLabel>

        <TextField
          size="small"
          type={isMobileBreakpoint ? 'tel' : 'number'}
          autoFocus
          fullWidth
          {...register('amount')}
        />
      </FormGroup>

      <FormGroup sx={{ mb: 2 }}>
        <FormLabel>Payment Method</FormLabel>

        <Controller
          name="paymentMethod"
          control={control}
          render={({ field: { value } }) => (
            <ToggleButtonGroup
              size="small"
              color="primary"
              fullWidth
              exclusive
              onChange={handleChangePaymentMethod}
              aria-label="Payment Method"
              value={value}
            >
              {(Object.keys(paymentMethodMapping) as Array<keyof typeof paymentMethodMapping>).map((method) => (
                <ToggleButton key={method} value={method}>
                  {paymentMethodMapping[method].title}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          )}
        />
      </FormGroup>

      <FormGroup sx={{ mb: 1 }}>
        <FormLabel>Order Method</FormLabel>

        <Controller
          name="orderMethod"
          control={control}
          render={({ field: { value } }) => (
            <ToggleButtonGroup
              size="small"
              color="primary"
              fullWidth
              exclusive
              onChange={handleChangeOrderMethod}
              aria-label="Order Method"
              value={value}
            >
              {(
                Object.keys(orderMethodMapping).filter(
                  (orderMethod) => orderMethod !== OrderMethodsEnum.AppDelivery,
                ) as Array<keyof typeof orderMethodMapping>
              ).map((method) => (
                <ToggleButton key={method} value={method}>
                  {orderMethodMapping[method].title}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          )}
        />
      </FormGroup>
      <FormGroup>
        <Controller
          name="printHdm"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Box alignItems="center" display="flex" flexDirection="row" justifyContent="space-between">
              <Typography>Print HDM</Typography>
              <Switch onChange={(_, isChecked) => onChange(isChecked)} checked={value} color="warning" />
            </Box>
          )}
        />
      </FormGroup>

      {orderMethod === OrderMethodsEnum.CallDelivery && (
        <>
          <FormGroup>
            <Controller
              name="excludeDeliveryPrice"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Box alignItems="center" display="flex" flexDirection="row" justifyContent="space-between">
                  <Typography>Exclude Delivery Price</Typography>
                  <Switch onChange={(_, isChecked) => onChange(isChecked)} checked={value} color="warning" />
                </Box>
              )}
            />
          </FormGroup>
          <PaymentFormDeliveryInputs />
        </>
      )}
    </>
  );
};

export default PaymentFormFields;
